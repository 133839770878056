<template>
  <div>
    <v-card-title class="pa-0">
      <p class="mb-0">
        <span v-t="'contractCard.contractId'" />
        <b v-text="contract.id" />
      </p>
    </v-card-title>
    <v-card-subtitle class="pa-0 pt-5">
      <b v-text="contract.description" />
    </v-card-subtitle>
  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true
    }
  }
}
</script>
